import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import banner1 from "../assets/banner/6.webp";
import banner2 from "../assets/banner/7.webp";
import banner3 from "../assets/banner/8.webp";
import banner4 from "../assets/banner/9.webp";
import banner10 from "../assets/banner/10.webp";
import banner11 from "../assets/banner/11.webp";
import { Link } from "react-router-dom";
import "./bannerstyle.css";

const Banner = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="w-full md-lg:mt-6">
      <div className="xl:w-[100%] md-lg:w-[100%] mx-auto">
        <div className="w-full flex flex-wrap md-lg:gap-8">
          <div className="w-full">
            <div className="xl:mt-8 md-lg:mt-0">
              <Carousel
                autoPlay={true}
                infinite={true}
                arrows={true}
                showDots={true}
                responsive={responsive}
              >
                {[banner1, banner2, banner3, banner4, banner10, banner11].map(
                  (img, i) => (
                    <Link
                      className="lg-md:h-[500px] h-auto w-full block"
                      key={i}
                      to="#"
                    >
                      <img className="md-lg:h-[12rem]" src={img} alt="" />
                    </Link>
                  )
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
